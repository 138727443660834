import { Link, useParams} from "react-router-dom";
import { Container, Dropdown } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../../assets/images/logo-black.svg';
import styles from "../../../styles/_header.module.scss";

const LeftSidebar = () => {
    let { agreementId } = useParams();

    return (
        <div className={`handling-left d-none d-lg-block`}>
            <ul>
                <li className={`active`}><a href={`/account/aftale/${agreementId}/dashboard`}><span className="icon-1"></span> Overblik</a></li>
                <li><a href={`/account/aftale/${agreementId}/arvinger`}><span className="icon-3"></span> Arvinger</a></li>
                <li><a href="#"><span className="icon-4"></span> Upload Regninger</a></li>
                <li><a href="#"><span className="icon-5"></span> Dokumenter</a></li>
            </ul>
        </div>
    )
}

export default LeftSidebar;
