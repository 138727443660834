import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styles from "../../styles/steps.module.scss";
import loginImage from '../../assets/images/login-image.png';
import resetPasswordSchema from "../../services/Schemas/ResetPasswordSchema";
import { resetPassword } from "../../services/Apis/Auth.service";
import {Link, useNavigate, useParams} from "react-router-dom";
import LayoutWithoutHeader from "../../components/FrontLayout/LayoutWithoutHeader";
import {Image} from "react-bootstrap";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'

function ResetPassword(props: any) {

    let navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated();

    const [showError, setShowError] = useState(false);

    const { register, watch, setValue, handleSubmit, formState:{ errors }, setError } = useForm({
        resolver: yupResolver(resetPasswordSchema)
    });

    let { token, email } = useParams();

    useEffect(() => {

        if (isAuthenticated) {
            navigate('/dashboard', { replace: true });
        }

        document.body.classList.add('white-background');
    }, []);

    const handleForgotPassword = async (data: any) => {
        setShowError(false);

        try {
            data.token = token;
            const response = await resetPassword(data);
            if (response.status == 200) {
                sessionStorage.setItem('showResetMessage', 'Your password changed successfully. Enter your details to login.');
                navigate('/', { replace: true });
            } else if(response.status == 422) {
                setShowError(false);
            } else {
                setShowError(true);
            }
        } catch (err) {
            setShowError(true);
        }
    }

  return (
      <>
          <LayoutWithoutHeader>
              <>
                  <div className={styles['q-step-row']}>
                      <Container className={styles['container']}>
                          <div className={styles['q-step-small-container']}>
                              <div className={`w-100 text-center`}><Image src={loginImage} /></div>
                              <div className={`${styles['q-step-title']} text-start fs-3`}>Indtast venligst din e-mailadresse for at nulstille adgangskoden.</div>
                              {showError &&
                                  <p className="text-danger pt-4 fs-5">Oops! Something went wrong. Please try again.</p>
                              }
                              <form onSubmit={handleSubmit(handleForgotPassword)}>
                                  <div className={styles['form-group']}>
                                      <input type="email" className={`${styles['form-control']} form-control`} {...register("email")} placeholder={"Email"} value={email} readOnly={true} />
                                      <div className="invalid-feedback-error">{errors?.email?.message}</div>
                                  </div>
                                  <div className={styles['form-group']}>
                                      <input type="password" className={`${styles['form-control']} form-control`} {...register("password")} placeholder={"Adgangskode"} />
                                      <div className="invalid-feedback-error">{errors?.password?.message}</div>
                                  </div>
                                  <div className={styles['form-group']}>
                                      <input type="password" className={`${styles['form-control']} form-control`} {...register("password_confirmation")} placeholder={"Bekræft adgangskode"} />
                                      <div className="invalid-feedback-error">{errors?.password_confirmation?.message}</div>
                                  </div>
                                  <div className={styles['form-group']}>
                                      <button type="submit" className={`blue-btn d-block w-100 ${styles['blue-btn-full-width']}`}>Send e-mail med Nulstil adgangskode 🔒</button>
                                  </div>
                                  <div className={`text-center d-block w-100 float-start pt-4`}>Already User? <Link to={'/'}>Log ind</Link></div>
                              </form>
                          </div>
                      </Container>
                  </div>
              </>

          </LayoutWithoutHeader>
      </>
  );
}

export default ResetPassword;
